import React from 'react'
import { useState ,useEffect} from 'react'
import {BiSolidDownArrow} from 'react-icons/bi'
import { Menu } from '@headlessui/react'
import { Link } from 'react-router-dom'

const Cat = ({size,text}) => {
    const [data,setData] = useState([])
    useEffect(()=> {
        const formData = new FormData()
        formData.append('action','select')
         fetch(`${process.env.REACT_APP_BASE_URL}projects.php`,{
          method:'POST',
          body:formData
          }).then(res=>res.json())
          .then(res=>{
            setData(res)
            
          })
      }
      , [])
  return (
    <>
    <Menu as='a' className='relative hidden lg:flex '>
     <Menu.Button className='text-white cursor-pointer  flex-col flex items-center justify-center text-sm tracking-wide font-light uppercase'>
          <div className='w-full flex items-center justify-center gap-2'>
                <span className={'lg:text-sm'}>{text}</span>
                <BiSolidDownArrow size={12}  className='text-white '/>
          </div>
     </Menu.Button>
     <Menu.Items className='hidden lg:flex flex-col absolute  left-0 z-50  bg-[#282828] w-full p-4 gap-4 text-white'>
         
     { data && data?.map((item,index)=>{
          if(item.link != ''){
            return(
                <Menu.Item key={index}>
                    <Link to={item.link} className='w-full whitespace-nowrap cursor-pointer hover:bg-white hover:text-black p-0.5 rounded-sm ' value="1">{
                        item.name.replace('_','\n')
                    }</Link>
                </Menu.Item>
            )
          }
        })
        }
          
     </Menu.Items>
     
    </Menu>
    
    <Menu as='a' className='relative lg:hidden'>
     <Menu.Button className='text-white cursor-pointer  flex-col flex items-center justify-center text-sm tracking-wide font-light uppercase'>
          <div className='w-full flex items-center justify-center gap-2'>
                <span className={'text-2xl'}>{text}</span>
                <BiSolidDownArrow size={24}  className='text-white '/>
          </div>
     </Menu.Button>
     <Menu.Items className='flex flex-col   bg-[#282828] w-full p-4 gap-4 text-white'>
        { data && data?.map((item,index)=>{
          if(item.link != ''){
            return(
                <Menu.Item key={index}>
                    <Link to={item.link} className='w-full whitespace-nowrap cursor-pointer hover:bg-white hover:text-black p-0.5 rounded-sm ' value="1">{
                        item.name.replace('_','\n')
                    }</Link>
                </Menu.Item>
            )
          }
        })
        }
     </Menu.Items>
    </Menu>
    </>
   
  )
}

export default Cat