import React, { useEffect ,useMemo,useRef,useState} from 'react'
import Logo from '../Assets/logo.jpg'
import { Link } from 'react-router-dom'
import Dropdown from './Dropdown'
import {GiHamburgerMenu} from 'react-icons/gi'
import Cat from './Cat'
import { useParams } from 'react-router-dom'

const Navbar = () => {
  const url = useParams()
  const mobileMenu = useRef()

  const toggleHandel = (e)=>{
    mobileMenu.current.classList.toggle('hidden')
  }
 
 

    
  return (
    <>
    <div id='nav'  className='w-full hidden  lg:flex items-center justify-between h-[14vh]  bg-[#282828]  shadow-lg shadow-black/10 px-12'>
        <img src={Logo} alt="Logo" className='h-16 '/>
        <div className='flex items-center justify-center gap-6'>
            <Link to='/' className='text-white text-sm tracking-wide font-light uppercase cursor-pointer'>Anasayfa</Link>
            <Dropdown  text='Projelerimiz'></Dropdown>
            <Link to='/about' className='text-white text-sm tracking-wide font-light uppercase cursor-pointer'>Hakkımızda</Link>
            <Link to='/galeri' className='text-white text-sm tracking-wide font-light uppercase cursor-pointer'>Galeri</Link>
            <Link to='/contact' className='text-white text-sm tracking-wide font-light uppercase cursor-pointer'>İletişim</Link>
            <Cat size={true} text={'Dijital Katalog'}></Cat>
        </div>
    </div>
    
    <GiHamburgerMenu onClick={e=>{toggleHandel(e)}} className='absolute lg:hidden right-5 z-[999999999] top-5'  size={32}></GiHamburgerMenu>
    

    <div id='nav' ref={mobileMenu} className='w-full hidden fixed top-0 left-0 z-[9999999] flex flex-col gap-4 items-start justify-start p-12 min-h-screen bg-[#282828]'>
      
    <img src={Logo} alt="Logo" className='h-16 '/>

      <Link to='/' className='text-white text-2xl tracking-wide font-light uppercase cursor-pointer'>Anasayfa</Link>
      <Dropdown  text={'Projelerimiz'} size={true}></Dropdown>
      <Link to='/about' className='text-white text-2xl tracking-wide font-light uppercase cursor-pointer'>Hakkımızda</Link>
      <Link to='/galeri' className='text-white text-2xl tracking-wide font-light uppercase cursor-pointer'>Galeri</Link>
      <Link to='/contact' className='text-white text-2xl tracking-wide font-light uppercase cursor-pointer'>İletişim</Link>
      <Cat  text={'Dijital Katalog'}></Cat>
      
    </div>
    </>
  )
}

export default Navbar