import React, { useEffect } from 'react'
import logoBg from '../Assets/logoBg.png'
import {BsFillBuildingFill} from 'react-icons/bs'
import {AiOutlineInfoCircle,AiFillPhone} from 'react-icons/ai'
import { useStateContext } from '../Context/ContextProvider'
import  {RxHamburgerMenu} from 'react-icons/rx'
import { Link } from 'react-router-dom'
import { useRef } from 'react'
const DashboardSidebar = () => {


    const sidebarRef = useRef()
    const { setToken,token,setUser } = useStateContext()
    
    useEffect(() => {
        if(!window.sessionStorage.getItem('token')){
            window.location.href = '/admin'
        }
    }, [window.sessionStorage.getItem('token')])


    const toggleHandel = (e)=>{
        sidebarRef.current.classList.toggle('hidden')
    }

    const logoutHandel = (e)=>{
        e.preventDefault()
        window.sessionStorage.removeItem('token')
        window.sessionStorage.removeItem('user')
        window.location.reload()
    }

  return (
    <>
     <div className='w-[17%] min-w-[17%] hidden lg:flex h-screen bg-[#282828]  flex-col items-center justify-start relative'>
        <img src={logoBg} className='w-full h-auto py-4 border-b border-white px-12'/>
        <div className='w-full h-auto flex flex-col items-start justify-start'>
            <ul className='w-full flex flex-col gap-6 p-6'>
               
              
                <Link to='/dashboard/projects' className='w-full rounded-md cursor-pointer h-auto flex items-center justify-start gap-2 p-4 text-white hover:text-[#282828] transition-all hover:bg-white '>
                    <BsFillBuildingFill size={32}/>
                    <span className='text-xl'>Projeler</span>
                </Link>
                <Link to='/dashboard/about'  className='w-full rounded-md cursor-pointer h-auto flex items-center justify-start gap-2 p-4 text-white hover:text-[#282828] transition-all hover:bg-white '>
                    <AiOutlineInfoCircle size={32}/>
                    <span className='text-xl'>Hakkımızda</span>
                </Link >
                <Link to='/dashboard/contact'  className='w-full rounded-md cursor-pointer h-auto flex items-center justify-start gap-2 p-4 text-white hover:text-[#282828] transition-all hover:bg-white '>
                    <AiFillPhone size={32}/>
                    <span className='text-xl'>İletişim</span>
                </Link >
            
            </ul>
            <button onClick={(e)=>{logoutHandel(e)}} className='w-3/4 rounded-md p-4 hover:bg-red-400 transition-colors bg-red-600 text-white absolute bottom-5 left-1/2 -translate-x-1/2'>
                Çıkış Yap
            </button>

        </div>

    </div>
    <RxHamburgerMenu onClick={(e)=>{toggleHandel(e)}} className='lg:hidden absolute left-6 top-4' color='black' size={32} />
    <div ref={sidebarRef} className='lg:hidden w-full h-screen fixed top-0 left-0 bg-white hidden flex  z-50 flex-col'>
    <div className='w-full flex flex-col items-start justify-start p-4 px-6'>
    <RxHamburgerMenu onClick={(e)=>{toggleHandel(e)}} className='lg:hidden ' color='black' size={32} />
    <div className='w-full h-auto flex flex-col items-start justify-start'>
            <ul className='w-full flex flex-col gap-6  py-2'>
               
              
                <Link to='/dashboard/projects' onClick={()=>{toggleHandel()}} className='w-full rounded-md cursor-pointer h-auto flex items-center justify-start gap-2 p-4 text-black hover:text-[#282828] transition-all hover:bg-white '>
                    <BsFillBuildingFill size={32}/>
                    <span className='text-xl'>Projeler</span>
                </Link>
                <Link to='/dashboard/about' onClick={()=>{toggleHandel()}}  className='w-full rounded-md cursor-pointer h-auto flex items-center justify-start gap-2 p-4 text-black hover:text-[#282828] transition-all hover:bg-white '>
                    <AiOutlineInfoCircle size={32}/>
                    <span className='text-xl'>Hakkımızda</span>
                </Link >
                <Link to='/dashboard/contact' onClick={()=>{toggleHandel()}}  className='w-full rounded-md cursor-pointer h-auto flex items-center justify-start gap-2 p-4 text-black hover:text-[#282828] transition-all hover:bg-white '>
                    <AiFillPhone size={32}/>
                    <span className='text-xl'>İletişim</span>
                </Link >
            
            </ul>
            <button onClick={(e)=>{logoutHandel(e)}} className='w-3/4 rounded-md p-4 hover:bg-red-400 transition-colors bg-red-600 text-white absolute bottom-5 left-1/2 -translate-x-1/2'>
                Çıkış Yap
            </button>

        </div>
    </div>

    </div>
    </>
  )
}

export default DashboardSidebar