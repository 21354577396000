import React from 'react'
import Navbar from '../Components/Navbar'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const GalleryDetails = () => {

    const {id} = useParams()
    const [data,setData] = useState([])
    const [resim,setResim] = useState([])
    const [plan,setPlan] = useState([])
    const [img,setImg] = useState([])
    useEffect(()=>{
        const formData = new FormData()
        formData.append('action','selectProjectbyId')
        formData.append('id',id)
        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`,{
            method:'POST',
            body:formData
        }).then(res=>res.json())
        .then(res=>{
            setResim(res[6])
            setPlan(res[7])
            setImg(res[10])
        })
    },[id])


    const bigİmage = (item) =>{
        const img = document.createElement('img')
        img.src = process.env.REACT_APP_BASE_URL+item
        img.className = 'w-full h-full object-cover'
        img.style.position = 'fixed'
        img.style.top = '0'
        img.style.left = '0'
        img.style.zIndex = '9999'
        img.style.cursor = 'pointer'
        img.style.transition = 'all 0.3s ease'
        img.style.maxHeight = '100vh'
        img.style.maxWidth = '100vw'
        img.style.objectFit = 'contain'
        img.style.backgroundColor = 'rgba(0,0,0,0.5)'
        img.style.backdropFilter = 'blur(5px)'
        img.style.borderRadius = '5px'
        img.style.padding = '10px'
        img.style.boxShadow = '0 0 10px 0 rgba(0,0,0,0.5)'
        document.body.appendChild(img)
        img.addEventListener('click',()=>{
            img.style.opacity = '0'
            setTimeout(()=>{
                img.remove()
            },300)
        })
    }

        

  return (
    <div className='w-full min-h-screen flex flex-col items-start justify-start'>
        <Navbar></Navbar>
        <div className='w-full flex flex-col items-start justify-start min-h-screen '>
        <label  htmlFor='resim' className='w-full text-3xl font-bold text-left font-mono p-12 pb-0'>Proje Resimleri</label>
        
        <div className='w-full h-auto min-h-screen p-12 gap-6 place-items-start  grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5'>
        {
           resim && resim.map((item,index)=>{
               return(
                <div key={index} className='w-full shadow-lg rounded-md hover:rounded-sm  shadow-black/50 overflow-hidden  hover:shadow-md hover:scale-105 duration-300 cursor-pointer flex flex-col items-center justify-center'>
                    <LazyLoadImage
                        alt={item}
                        effect="blur"
                        src={process.env.REACT_APP_BASE_URL+item} 
                        className='w-full min-h-full block'
                        onClick={()=>{bigİmage(item)}}
                    />
                </div>
               )
           })
        }

        </div>
        <label  htmlFor='resim' className='w-full text-3xl font-bold text-left font-mono p-12 pb-0'>Plan Resimleri</label>
        
        <div className='w-full h-auto p-12 gap-6 place-items-start min-h-screen grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5'>
        {
           plan && plan.map((item,index)=>{
               return(
                <div key={index} className='w-full drop-shadow-lg rounded-md hover:rounded-sm bg-transparent  drop-shadow-black/50 overflow-hidden  hover:drop-shadow-md hover:scale-105 duration-300 cursor-pointer flex flex-col items-center justify-center'>
                    <LazyLoadImage
                        alt={item}
                        effect="blur"
                        src={process.env.REACT_APP_BASE_URL+item} 
                        className='w-full'
                        onClick={()=>{bigİmage(item)}}

                    />
                </div>
               )
           })
        }

        </div>
        <label  htmlFor='resim' className='w-full text-3xl font-bold text-left font-mono p-12 pb-0'>İç Görseller</label>
        
        <div className='w-full h-auto p-12 gap-6 place-items-start min-h-screen grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5'>
        {
           img && img.map((item,index)=>{
               return(
                <div key={index} className='w-full drop-shadow-lg rounded-md hover:rounded-sm bg-transparent  drop-shadow-black/50 overflow-hidden  hover:drop-shadow-md hover:scale-105 duration-300 cursor-pointer flex flex-col items-center justify-center'>
                    <LazyLoadImage
                        alt={item}
                        effect="blur"
                        src={process.env.REACT_APP_BASE_URL+item} 
                        className='w-full h-full'
                        onClick={()=>{bigİmage(item)}}

                    />
                </div>
               )
           })
        }

        </div>
        </div>
       
    </div>
  )
}

export default GalleryDetails