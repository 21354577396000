import React, { useRef } from 'react'
import Navbar from '../Components/Navbar'
import { motion } from "framer-motion"
import about from '../Assets/about.jpg'
import mega from '../Assets/mega.webp'
import {AiFillInstagram,AiFillFacebook,AiOutlineMail,AiOutlinePhone,AiFillYoutube} from 'react-icons/ai'
import Footer from '../Components/Footer'
import { useState, useEffect } from 'react'

const About = ({nav = true , footer = true}) => {
    const [contact,setContact] = useState([])
    const [data,setData] = useState([])
    const mapRef = useRef()
    useEffect (()=>{
        document.title = 'Hakkımızda'
        const formData = new FormData()
        formData.append('action','select')
         fetch(`${process.env.REACT_APP_BASE_URL}about.php`,{
          method:'POST',
          body:formData
          }).then(res=>res.json())
          .then(res=>{
           setData(res.text)

          })

          const fData = new FormData()
          fData.append('action','select_contact')
           fetch(`${process.env.REACT_APP_BASE_URL}contact.php`,{
            method:'POST',
            body:formData
            }).then(res=>res.json())
            .then(res=>{
             setContact(res)
             mapRef.current.innerHTML = res.konum
             
            })
      }
        , [])
  


    return (
        <div className='w-full min-h-screen flex flex-col items-start justify-start'>
           {
            nav == true ? <Navbar></Navbar> : null
           }
            <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}

                className='w-full flex flex-col   items-center'>
                <h1 className='text-3xl w-full p-10 text-center relative  bg-white  font-normal z-10 tracking-[10px] uppercase '>
                    Başlangıçlar iddialıysa,<br>
                    </br> gelecek daha güçlü kurulur
                </h1>
                <div className='w-full flex lg:h-96 h-auto p-6 flex-col lg:flex-row gap-12'>
                    <img src={mega} className='w-full lg:w-1/2 h-full object-cover' />
                    <motion.div
                        className='flex-1 flex flex-col items-start justify-start'
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1.5 }}
                    >
                        <h1 className='text-3xl font-light tracking-[3px] w-full p-2 text-left border-b border-slate-400'>Hakkımızda</h1>
                        <span  className=' text-slate-600 p-4 flex flex-col gap-2 font-light'>
                           {data}
                        </span>
                    </motion.div>
                </div>
            </motion.div>
            <motion.div
            className='w-full grid place-items-center grid-cols-2 gap-6 lg:grid-cols-5 lg:h-32 h-auto '
            >
               <a href={'tel:'+contact?.phone} className='group cursor-pointer' alt='Telefon'>
               <AiOutlinePhone className='text-6xl border group group-hover:bg-[#282828] hover:text-white hover:border-none transition-all border-black rounded-full p-4'/>
              </a>
               <a href={'mailto:'+contact?.mail} className='group cursor-pointer' alt='Mail'>
               <AiOutlineMail className='text-6xl border group group-hover:bg-[#282828] hover:text-white hover:border-none transition-all border-black rounded-full p-4'/>
              </a>
               <a href={contact?.facebook} className='group cursor-pointer' alt='Facebook'>
               <AiFillFacebook className='text-6xl border group group-hover:bg-[#282828] hover:text-white hover:border-none transition-all border-black rounded-full p-4'/>
              </a>
               <a href={contact?.instagram} className='group cursor-pointer' alt='İnstagram'>
               <AiFillInstagram className='text-6xl border group group-hover:bg-[#282828] hover:text-white hover:border-none transition-all border-black rounded-full p-4'/>
              </a>
               <a href={contact?.youtube} className='group cursor-pointer' alt='İnstagram'>
               <AiFillYoutube className='text-6xl border group group-hover:bg-[#282828] hover:text-white hover:border-none transition-all border-black rounded-full p-4'/>
              </a>
                


            </motion.div>
            <div className='w-full px-6 py-4 map ' ref={mapRef}>
                <iframe
                    className='w-full h-[50vh] rounded-sm'
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >

                </iframe>
            </div>
           {footer == true ? <Footer></Footer> : null}

        </div>
    )
}

export default About