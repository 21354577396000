import React from 'react'
import Home from './Pages/Home'
import Project from './Pages/Project'
import { Routes, Route } from 'react-router-dom'
import About from './Pages/About'
import Contact from './Pages/Contact'
import Login from './Pages/admin/Login'
import { Dashboard } from './Pages/admin/Dashboard'
import Projects from './Pages/admin/pages/Projects'
import AdminAbout from './Pages/admin/pages/AdminAbout'
import AdminContact from './Pages/admin/pages/AdminContact'
import AddProjects from './Pages/admin/pages/AddProjects'
import EditProjects from './Pages/admin/pages/EditProjects'
import Gallery from './Pages/Gallery'
import GalleryDetails from './Pages/GalleryDetails'

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path='/project/:id' element={<Project />} />
      <Route path='/about' element={<About></About>}></Route>
      <Route path='/contact' element={<Contact></Contact>}  ></Route>
      <Route path='/galeri' element={<Gallery></Gallery>} ></Route>
      <Route path='/galeri/:id' element={<GalleryDetails/>} ></Route>
      <Route path='/admin' element={<Login></Login>}></Route>
      <Route path='/dashboard' element={<Dashboard></Dashboard>}>
        <Route path='/dashboard/projects' element={<Projects></Projects>}></Route>
        <Route path='/dashboard/about' element={<AdminAbout />}></Route>
        <Route path='/dashboard/contact' element={<AdminContact />}></Route>
        <Route path='/dashboard/add' element={<AddProjects/>}></Route>
        <Route path='/dashboard/edit/:id' element={<EditProjects/>}></Route>
      </Route>

    </Routes>
  )
}

export default App