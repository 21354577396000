import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { motion } from "framer-motion"
import Footer from '../Components/Footer';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';
import { Autoplay } from 'swiper/modules';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Navigation } from 'swiper/modules';

import 'react-lazy-load-image-component/src/effects/blur.css';

const Project = () => {

    const descriptionRef = useRef(null)
    const mapRef = useRef(null)
    const featuresRef = useRef(null)
    const { id } = useParams()
    const [data, setData] = useState([])
    useEffect(() => {
      
        const formData = new FormData()
        formData.append('action', 'selectProjectbyId')
        formData.append('id', id)
        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
            method: 'POST',
            body: formData
        }).then(res => res.json())
            .then(res => {
                document.title = res[1] ?? res[1].replace('_', '')
                setData(res)
                descriptionRef.current.innerHTML = res[2]
                featuresRef.current.innerHTML = res[3]
                mapRef.current.innerHTML = res[4]
                
            })
            .finally(() => {
                
                
            })
    }, [id])

    const bigİmage = (item) =>{
        const img = document.createElement('img')
        img.src = process.env.REACT_APP_BASE_URL+item
        img.className = 'w-full h-full object-cover'
        img.style.position = 'fixed'
        img.style.top = '0'
        img.style.left = '0'
        img.style.zIndex = '9999'
        img.style.cursor = 'pointer'
        img.style.transition = 'all 0.3s ease'
        img.style.maxHeight = '100vh'
        img.style.maxWidth = '100vw'
        img.style.objectFit = 'contain'
        img.style.backgroundColor = 'rgba(0,0,0,0.5)'
        img.style.backdropFilter = 'blur(5px)'
        img.style.borderRadius = '5px'
        img.style.padding = '10px'
        img.style.boxShadow = '0 0 10px 0 rgba(0,0,0,0.5)'
        document.body.appendChild(img)
        img.addEventListener('click',()=>{
            img.style.opacity = '0'
            setTimeout(()=>{
                img.remove()
            },300)
        })
    }

  


    return (
        <div className='w-full min-h-screen flex flex-col items-start justify-start'>
            <Navbar></Navbar>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}

                className='w-full h-[75vh]  '>
                <Swiper
                    effect={'fade'}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    navigation={true}
                    modules={[Pagination, EffectFade, Autoplay,Navigation]}
                    autoplay={{
                        delay: 5500,
                        disableOnInteraction: false
                    }}
                    className='w-full h-screen bg-[#282828] border-white '
                >

                    {
                        data[6]?.map((item, index) => {
                            return (
                                <SwiperSlide key={index} className='relative h-screen w-full'>
                                    <motion.span
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 2 }}
                                        className='w-full h-full text-white text-5xl tracking-[10px] uppercase flex items-end  lg:pb-40 pb-20  justify-end lg:px-16 px-4  bg-black/40 absolute left-0 top-0' >
                                        <h1 className='lg:flex hidden items-center justify-center p-4 rounded-sm'>
                                            {data[1].replace('_', '')}
                                        </h1>
                                        <h1 className='lg:hidden items-center justify-center text-sm bg-black/60 p-4 rounded-sm'>
                                            {data[1].replace('_', '')}
                                        </h1>
                                    </motion.span>
                                    <img alt={index} loading='lazy' src={process.env.REACT_APP_BASE_URL + item} className='w-full object-cover h-screen bg-center  '></img>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </motion.div>
            <div className='w-full h-auto flex flex-col gap-6 mt-36 2xl:mt-72  lg:mt-40 items-center justify-center'>

                <motion.div
                    initial={{ opacity: 0, x: -500 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    
                    className='w-full   h-full flex-col lg:flex-row flex items-start justify-center gap-6 mt-24 lg:mt-12 lg:px-20 px-6 '>
                    
                    {data[7] && <img alt={data[0]} loading='lazy' className='max-h-full w-full lg:w-1/2' src={process.env.REACT_APP_BASE_URL + data[7][0]} />}

                    <div className=' lg:w-1/2 w-full min-h-full h-full flex flex-col items-start rounded-md justify-start  '>
                        <div className='w-full flex items-center justify-start p-4 border-b '>
                            <a className='text-3xl font-light tracking-[5px] '>Proje Özellikleri</a>

                        </div>
                        <div ref={descriptionRef} className=' w-full h-auto grid lg:grid-cols-2 gap-4 md:grid-cols-2 p-2 grid-cols-1'>

                        </div>
                    </div>
                </motion.div>
                <motion.div

                    initial={{ opacity: 0, x: 500 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className='w-full box h-auto lg:flex-row-reverse flex flex-col  items-start justify-between gap-12 mt-32 lg:mt-12 lg:px-20 px-6 '>
                    {
                        data[7] && <img className='max-h-full w-full lg:w-1/2' src={process.env.REACT_APP_BASE_URL + data[7][1]} />
                    }
                    <div ref={featuresRef} className=' lg:w-1/2  w-full text-lg h-full  grid lg:grid-cols-2 gap-8 md:grid-cols-2 p-2 grid-cols-1 '>

                    </div>

                </motion.div>

                <Swiper
                    
                    slidesPerView={3}
                    spaceBetween={10}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    modules={[Pagination, EffectFade, Autoplay,Navigation]}
                    navigation={true}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 0,
                        },

                    }
                    }
                    className='w-full h-[70vh] relative grid place-items-center border-white p-2 '>
                    {
                         data[10] ? Object.values(data[10]).map((item, index) => {
                            return (
                                <SwiperSlide key={index} className='sl' >
                                    <img alt={index} loading='lazy' onClick={()=>{bigİmage(item)}}  src={process.env.REACT_APP_BASE_URL + item} className='max-h-64 transition-all hover:shadow-none shadow-2xl shadow-black/50 '></img>
                                </SwiperSlide>
                            )
                         }) :null

                    }
                </Swiper>

                <div ref={mapRef} className='w-full map flex items-center justify-center lg:px-12 px-2 py-12 mt-12 bg-slate-50'>

                </div>
                <div className='w-full lg:p-12 p-2 mt-12 '>
                    <iframe
                        className='w-full h-[100vh] rounded-sm'
                        src={data[5]}
                    >

                    </iframe>
                </div>

            </div>
            <Footer></Footer>

        </div>
    )
}

export default Project