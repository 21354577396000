import React from 'react'
import Navbar from '../Components/Navbar'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'




const Gallery = () => {


    const [data, setData] = useState([])


    useEffect(() => {
        const formdata = new FormData()
        formdata.append('action', 'gallery')
        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(res => {
                setData(res)

            })
    }, [])

    return (
        <div className='flex w-full items-start justify-start flex-col min-h-screen '>
            <Navbar></Navbar>
            <div className='w-full items-start  min-h-screen grid grid-cols-1 gap-6 p-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 '>
                {
                    data && data.map((item, index) => {
                        const bg = item.resim.split(',')
                        const a = bg[0]
                        return (
                            <Link to={'/galeri/'+item.id} className='w-full flex overflow-hidden flex-col items-center justify-center  drop-shadow-xl shadow-black '>
                                <div key={index} className='w-full h-full md:h-1/2 overflow-hidden rounded-md bg-center group bg-no-repeat bg-cover relative  '>
                                <LazyLoadImage
                                            src={process.env.REACT_APP_BASE_URL + a}
                                            alt={item.name}
                                            className='w-full h-full min-h-[300px] object-cover'
                                        />
                                    <span className='w-0 group-hover:w-full  origin-bottom-left h-full bg-black group-hover:bg-black/50 group-hover:z-30 rounded-md grid place-items-center absolute opacity-0 left-0 group-hover:opacity-100 duration-300 top-0'>
                                       
                                        <p className='text-3xl text-white'>
                                            {
                                                item.name.replace('_', ' ')
                                            }
                                        </p>
                                    </span>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>




        </div>
    )
}

export default Gallery