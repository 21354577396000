import React from 'react'
import { AiFillYoutube } from 'react-icons/ai'
import { BsBook } from 'react-icons/bs'
import { InputSwitch } from 'primereact/inputswitch';
import { Editor } from 'primereact/editor';
import { Grid } from 'react-loader-spinner';
import { Toaster, toast } from 'react-hot-toast';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const EditProjects = () => {
    const { id } = useParams()
    const loader = useRef()
    const resimContainer = useRef()
    const planContainer = useRef()
    const imgContainer = useRef()

    const [id_, setId] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [features, setFeatures] = useState('')
    const [konum, setKonum] = useState('')
    const [youtube, setYoutube] = useState('')
    const [link, setLink] = useState('')
    const [resim, setResim] = useState([])
    const [dresim, setDresim] = useState([])
    const [dplan, setDplan] = useState([])
    const [plan, setPlan] = useState([])
    const [checked, setChecked] = useState();
    const [img, setİmg] = useState([])
    const [dimg, setDimg] = useState([])
   

    useEffect(() => {
        const formData = new FormData()
        formData.append('action', 'selectProject')
        formData.append('id', id)
        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(data => {
                setName(data.name)
                setDescription(data.description)
                setFeatures(data?.features)
                setKonum(data?.konum)
                setYoutube(data.youtube)
                setLink(data.link)
                setChecked(data.status === '1' ? true : false)
                setDresim(data.resim)
                setDplan(data.plan)
                setId(data.id)
                setDimg(data.img)
                console.log(checked)


            })
    }, [])




    const submitHandel = () => {
       
            loader.current.classList.remove('hidden')
            loader.current.classList.add('flex')
            const formData = new FormData()
            formData.append('action', 'update')
            formData.append('id', id_)
            formData.append('name', name)
            formData.append('description', description)
            formData.append('features', features)
            formData.append('konum', konum)
            formData.append('youtube', youtube)
            formData.append('link', link)
            formData.append('checked', checked ? 1 : 0)


            for (let i = 0; i < resim.length; i++) {
                formData.append(`resim[${i}]`, resim[i])
            }

            for (let i = 0; i < plan.length; i++) {
                formData.append(`plan[${i}]`, plan[i])
            }

            for (let i = 0; i < img.length; i++) {
                formData.append(`img[${i}]`, img[i])
            }


            fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(data => {
                    loader.current.classList.add('hidden')
                    loader.current.classList.remove('flex')
                    if (data.status === 200) {
                        toast.success(data.message)
                    }
                    else {
                        toast.error(data.message)
                    }
                })

        

    }
    useEffect(() => {
        if (resim.length > 0) {
            for (let i = 0; i < resim.length; i++) {
                const reader = new FileReader()
                reader.onload = function (e) {
                    const img = document.createElement('img')
                    img.src = e.target.result
                    img.className = 'w-32 h-32 object-cover btn'
                    img.loading = 'lazy'
                    resimContainer.current.appendChild(img)
                    img.addEventListener('click', () => {
                        resimContainer.current.removeChild(img)
                        setResim(resim.filter((item) => item !== resim[i]))
                    })
                }
                reader.readAsDataURL(resim[i])
            }
        }
        else {

        }

    }, [resim])
    useEffect(() => {
        if (plan.length > 0) {
            for (let i = 0; i < plan.length; i++) {
                const reader = new FileReader()
                reader.onload = function (e) {
                    const img = document.createElement('img')
                    img.src = e.target.result
                    img.className = 'w-32 h-32 object-cover btn'
                    img.loading = 'lazy'
                    planContainer.current.appendChild(img)
                    img.addEventListener('click', () => {
                        planContainer.current.removeChild(img)
                        setPlan(plan.filter((item) => item !== plan[i]))
                    })
                }
                reader.readAsDataURL(plan[i])
            }
        }
        else {

        }

    }, [plan])
    useEffect(() => {
        if (img.length > 0) {
            for (let i = 0; i < img.length; i++) {
                const reader = new FileReader()
                reader.onload = function (e) {
                    const imgg = document.createElement('img')
                    imgg.src = e.target.result
                    imgg.className = 'w-32 h-32 object-cover btn'
                    imgg.loading = 'lazy'
                    imgContainer.current.appendChild(imgg)
                    imgg.addEventListener('click', () => {
                        imgContainer.current.removeChild(imgg)
                        setİmg(img.filter((item) => item !== imgg[i]))
                    })
                }
                reader.readAsDataURL(img[i])
            }
        }
        else {

        }

    }, [img])


    const delteResim = (item) => {
        const formData = new FormData()
        formData.append('action', 'deleteResim')
        formData.append('name', name)
        formData.append('id', item)
        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(data => {
                if(data.status===200){
                    toast.success(data.message)
                }else{
                    toast.error(data.message)
                }
            })
    }
    const deletePlan = (item) => {
        console.log(dplan)
        setDplan(dplan.filter((i) => i !== ''))
       
            const formData = new FormData()
            formData.append('action', 'deletePlan')
            formData.append('name', name)
            formData.append('id', item)
            fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(data => {
                    if(data.status===200){
                        toast.success(data.message)
                    }else{
                        toast.error(data.message)
                    }
                })
        
    }

    const deleteImg = (item) => {
        
        setİmg(img.filter((i) => i !== ''))
       
            const formData = new FormData()
            formData.append('action', 'deleteImg')
            formData.append('name', name)
            formData.append('id', item)
            fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(data => {
                    if(data.status===200){
                        toast.success(data.message)
                    }else{
                        toast.error(data.message)
                    }
                })
        
    }

    return (
        <div className='w-full h-screen flex flex-col lg:flex-row  items-start justify-start mt-12 lg:mt-0'>
            <Toaster position='bottom-center' />
            <div className='w-full min-h-screen z-[999999999999] hidden fixed top-0 left-0 bg-white  items-center justify-center' ref={loader}>
                <Grid
                    color='red'
                ></Grid>
            </div>
            <div className='lg:w-1/2 w-full h-full lg:overflow-y-scroll  border-r border-slate-300 flex flex-col gap-4 p-6'>
                <div className='flex w-full border-b border-slate-200 p-2  items-start justify-between gap-1'>
                    <label>
                        Satış Durumu
                    </label>
                    <div className='flex items-center justify-center gap-4'>

                        <InputSwitch checked={checked==1?true:false} onChange={(e) => setChecked(e.value)} />


                    </div>
                </div>
                <div className='flex w-full flex-col items-start justify-start gap-1'>
                    <label>
                        Proje Adı
                    </label>
                    <input onChange={(e) => { setName(e.target.value) }} value={name} className='w-full h-12 rounded-md border border-gray-300 p-4' />
                </div>


                <div className='w-full flex flex-col items-start justify-start gap-1'>
                    <label>
                        Proje Açıklaması
                    </label>
                    <Editor value={description} onTextChange={(e) => setDescription(e.htmlValue)} style={{ height: '200px' }} />

                </div>
                <div className='w-full flex flex-col items-start justify-start gap-1'>
                    <label>
                        Proje Özellikleri
                    </label>
                    <Editor value={features} onTextChange={(e) => setFeatures(e.htmlValue)} style={{ height: '200px' }} />

                </div>
                <div className='w-full flex  items-start justify-start gap-4'>
                    <FaMapMarkerAlt size={48} color='blue' />
                    <input onChange={(e) => { setKonum(e.target.value) }} value={konum} className='w-full h-12 rounded-md border border-gray-300 p-4' />
                </div>
                <div className='w-full flex  items-start justify-start gap-4'>
                    <AiFillYoutube size={48} />
                    <input onChange={(e) => { setYoutube(e.target.value) }} value={youtube} className='w-full h-12 rounded-md border border-gray-300 p-4' />
                </div>
                <div className='w-full flex  items-start justify-start gap-4'>
                    <BsBook size={48} />
                    <input onChange={(e) => { setLink(e.target.value) }} value={link} className='w-full h-12 rounded-md border border-gray-300 p-4' />
                </div>
                <button onClick={(e) => { submitHandel(e) }} className='w-full lg:flex hidden text-white text-xl font-light uppercase rounded-md items-center justify-center h-16 p-2 bg-indigo-600'>
                    Kaydet
                </button>
            </div>



            <div className=' w-full lg:w-1/2 min-h-screen h-auto  overflow-y-scroll mt-96 lg:mt-0  flex-col gap-8 p-6 items-start justify-start'>
                <div className='w-full flex flex-col items-center justify-start gap-4'>
                    <label className='text-lg text-left lg:text-center w-full'>
                        Proje Resimleri
                    </label>
                    <div className='w-full h-[1px] bg-black'>

                    </div>
                    <div className='w-full flex flex-col gap-4'>
                        <Accordion className='w-full' >
                            <AccordionTab header="Plan Görselleri">
                                <div className='w-full flex items-start justify-start'>
                            <label htmlFor='plan'
                                className='w-full cursor-pointer h-16 hover:bg-black hover:text-white border-dashed border border-black flex items-center justify-center rounded-md  transition-all'>
                                Plan görselleri seçmek için tıklayın
                            </label>
                            <input multiple onChange={(e) => { setPlan(e.target.files) }} type='file' id='plan' className='hidden' />
                        </div>
                        <div ref={planContainer} className='max-h-64 h-auto overflow-scroll w-full grid grid-cols-4 gap-4 place-items-center'>
                            {
                                dplan.map((item, index) => {
                                    return (
                                        item ? <LazyLoadImage alt={item} effect='blur' key={index} onClick={(e) => { deletePlan(item) }} src={`${process.env.REACT_APP_BASE_URL}${item}`} className='w-32 h-32 object-cover' /> : null
                                    )
                                })
                            }

                        </div>
                        </AccordionTab>
                        </Accordion>
                    </div>
                </div>
                <div className='w-full mt-6 flex flex-col items-center justify-start gap-4'>
                    <Accordion className='w-full' >
                        <AccordionTab header="Proje Görselleri">
                        <div className='w-full flex flex-col gap-4'>
                        <div className='w-full flex items-start justify-start'>
                            <label htmlFor='resim'
                                className='w-full cursor-pointer h-16 hover:bg-black hover:text-white border-dashed border border-black flex items-center justify-center rounded-md  transition-all'>
                                Binanın görselleri seçmek için tıklayın
                            </label>
                            <input multiple onChange={(e) => { setResim(e.target.files) }} type='file' id='resim' className='hidden' />
                        </div>
                        <div ref={resimContainer} className='max-h-64 h-auto overflow-scroll w-full grid grid-cols-4 gap-4 place-items-center'>
                            {
                                dresim.map((item, index) => {
                                    return (
                                        item ?
                                            <img key={index} onClick={(e) => { delteResim(item) }}
                                                src={`${process.env.REACT_APP_BASE_URL}${item}`} alt={index}
                                                className='w-32 h-32 object-cover  ' />
                                            : null
                                    )
                                })
                            }
                        </div>

                    </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div className='w-full mt-6 flex flex-col items-center justify-start gap-4'>

                    <Accordion className='w-full' >
                        <AccordionTab header="İç Görseller">
                        <div className='w-full flex flex-col gap-4'>
                        <div className='w-full flex items-start justify-start'>
                            <label htmlFor='img'
                                className='w-full cursor-pointer h-16 hover:bg-black hover:text-white border-dashed border border-black flex items-center justify-center rounded-md  transition-all'>
                                İç mekan görselleri seçmek için tıklayın
                            </label>
                            <input multiple onChange={(e) => { setİmg(e.target.files) }} type='file' id='img' className='hidden' />
                        </div>
                        <div ref={imgContainer} className='box-container max-h-64 h-auto  overflow-scroll w-full grid grid-cols-4 gap-4 place-items-center'>
                            {
                                dimg &&  dimg.map((item, index) => {
                                    return (
                                        item ?
                                            <img key={index}  onClick={(e) => { deleteImg(item) }}
                                                src={`${process.env.REACT_APP_BASE_URL}${item}`} alt={index}
                                                className='w-32 h-32 object-cover  ' />
                                            : null
                                    )
                                })
                            }
                        </div>

                    </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <button onClick={(e) => { submitHandel(e) }} className='w-full lg:hidden flex mt-5 text-white text-xl font-light uppercase rounded-md items-center justify-center h-16 bg-indigo-600'>
                    Kaydet
                </button>

            </div>


        </div>
    )
}

export default EditProjects