import React from 'react'
import Navbar from '../Components/Navbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules';
import Logo from '../Assets/logoBg.png'
import 'swiper/css/pagination';
import { motion } from 'framer-motion';
import About from '../Pages/About';
import { useEffect , useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import 'swiper/css/navigation';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Home = () => {

    const [data,setData] = useState([])
    const [bg,setBg]= useState('')
    useEffect (()=>{
        document.title = 'Anasayfa'

        const formData = new FormData()
        formData.append('action','selectAll')
         fetch(`${process.env.REACT_APP_BASE_URL}projects.php`,{
          method:'POST',
          body:formData
          }).then(res=>res.json())
          .then(res=>{
            setData(res)
          })
      }
        , [])

    return (
        <div className='flex w-full items-start justify-between flex-col min-h-screen '>
            <Navbar></Navbar>
            <img src={Logo} alt="Logo" className='h-8 invert md:hidden absolute top-6 left-6 z-50 ' />
            <div className='w-full lg:flex h-full flex items-start justify-start flex-1'>
           
            <Swiper
                pagination={{
                    dynamicBullets: true,
                }}
                modules={[Pagination,Navigation]}
                navigation={true}
                className='w-full h-screen md:h-[86vh] flex-1 bg-[#282828] border-white '
            >
               
               {
                data?.map((item,index)=>{
                   const bg = item.resim.split(',')
                   const a= bg[0].replace('[','').replace('"','').replace('"','').replace('\\','')

                    return(
                        
                        <SwiperSlide key={index}>
                     
                       <motion.div
                        initial={{opacity:0}}
                        animate={{opacity:1}}
                        transition={{duration:3}}
                         className='w-full h-full flex-1 relative '  >
                            <LazyLoadImage effect='blur' loading='lazy' alt={item} src={process.env.REACT_APP_BASE_URL+a} className='w-full md:object-cover hidden md:block object-contain h-screen absolute -z-20 left-0 top-0'></LazyLoadImage>
                            <LazyLoadImage effect='blue' loading='lazy' alt={item} src={process.env.REACT_APP_BASE_URL+a} className='w-full  object-cover object-center h-screen absolute -z-20 left-0 top-0'></LazyLoadImage>
                            <span className='w-full h-full absolute -z-[1] top-0 left-0 bg-black/30'/>
                            <div className='lg:p-12 p-32 px-6 z-[999999] w-full h-full flex items-end justify-start'>
                                <div className='md:w-1/2 w-full flex-col flex items-start justify-start md:gap-6 gap-1'>
                                    <h1 className='cursor-pointer md:ml-4 antialiased text-lg md:text-5xl font-bold text-white'>{item.name.replace('_',' ')}</h1>
                                    
                                    <Link to={'/project/'+item.id} className='cursor-pointer text-sm md:text-lg transition-all font-normal tracking-widest uppercase text-white bg-[#282828] hover:bg-white hover:text-black p-2 rounded-sm'>Detaylı Bilgi</Link>
                                </div>
                            </div>
                        </motion.div>
                     
                    </SwiperSlide>
                    );
                })
               }
                
                
            </Swiper>
            
            </div>
           <div className='md:hidden w-full h-auto'>
           <About></About>
           </div>
            
            
        </div>
    )
}

export default Home