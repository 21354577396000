import React from 'react'
import { useEffect,useState } from 'react'


const AdminAbout = () => {


  const [text, setText] = useState('')


  useEffect(() => {
    const formData = new FormData()
    formData.append('action','select')
    fetch(`${process.env.REACT_APP_BASE_URL}about.php`,{
      method:'POST',
      body:formData
      }).then(res=>res.json())
      .then(res=>{
        setText(res.text)
      })
  }, [])      


  const updateHandel = ()=>{

    const formData = new FormData()
    formData.append('action','update')
    formData.append('text',text)
    fetch(`${process.env.REACT_APP_BASE_URL}about.php`,{
      method:'POST',
      body:formData
      }).then(res=>res.json())
      .then(res=>{
        console.log(res)
      })



  }

  return (
    <div className='w-full flex flex-col items-end justify-start p-6 mt-12 lg:mt-0 h-screen'>
      <textarea onChange={(e)=>{setText(e.target.value)}} value={text} className='w-full h-3/4 resize-none bg-slate-50 shadow-sm shadow-black/30 p-2 border-2 border-gray-300 rounded-md' placeholder='Hakkımızda' />
      <button onClick={(e)=>{updateHandel()}} className='bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4'>Kaydet</button>

    </div>
  )
}

export default AdminAbout