import React from 'react'
import Navbar from '../Components/Navbar'
import { motion } from 'framer-motion'
import { useRef } from 'react'
import { useEffect } from 'react'
import Footer from '../Components/Footer'
import { useState } from 'react'
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';
import { AiOutlinePhone, AiOutlineMail, AiFillFacebook, AiFillInstagram ,AiFillYoutube } from 'react-icons/ai'


const Contact = () => {

  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [message, setMessage] = useState('')
  const [contact, setContact] = useState([])
  const mapRef = useRef()
  useEffect(() => {
    document.title = 'İletişim'
    const fData = new FormData()
    fData.append('action', 'select_contact')
    fetch(`${process.env.REACT_APP_BASE_URL}contact.php`, {
      method: 'POST',
      body: fData
    }).then(res => res.json())
      .then(res => {
        mapRef.current.innerHTML = res.konum
        setContact(res)

      })
  }
    , [])

  const templateParams = {
    name: name,
    mail: mail,
    message: message,
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (name.length <= 0 && mail.length <= 0 && message.length <= 0) {
      toast.error('Lütfen tüm alanları doldurunuz.')
      return false
    } else {
      emailjs.send('service_5bcznyc', 'template_vduzp4u', {
        from_name: mail,
        to_name: name,
        message: message
      }, 'uNmRk8fyIVqFJq6Tr')
        .then((result) => {
          toast.success(result.text)
          setName('')
          setMail('')
          setMessage('')
        }, (error) => {
          toast.error(error.text)
        });

    }
  }

  return (
    <div className='w-full flex flex-col items-start justify-start min-h-screen'>
      <Navbar></Navbar>
      <Toaster position='top-left' />

      <div className='w-full h-full flex-1 flex flex-col lg:flex-row items-center justify-center lg:p-12 py-6 lg:py-32  gap-8'>
        <div className='flex flex-col w-full lg:w-1/2 px-6 lg:px-0 justify-center items-end  gap-4'>
          <h1 className='text-xl tracking-[7px] text-black uppercase w-full  text-left py-3 border-b border-slate-300'>Bizimle iletişime geçin</h1>
          <input onChange={(e) => { setName(e.target.value) }} value={name} type='text' placeholder='Adınız' className='w-full p-2 border-2 border-gray-300 rounded-sm'></input>
          <input onChange={(e) => { setMail(e.target.value) }} value={mail} type='text' placeholder='Mail adresiniz' className='w-full p-2 border-2 border-gray-300 rounded-sm'></input>
          <textarea onChange={(e) => { setMessage(e.target.value) }} value={message} placeholder='Mesajınız' className='w-full min-h-[7rem] p-2 border-2 border-gray-300 rounded-sm'></textarea>
          <button onClick={(e) => { sendEmail(e) }} className='lg:w-40 w-full p-2 transition-all bg-[#282828] font-light tracking-[10px] uppercase hover:bg-[#484848] text-white rounded-md'>Gönder</button>
          <div className='w-full h-[20vh] flex items-center justify-between'>
            <a href={'tel:' + contact?.phone} className='group flex items-center justify-center gap-2 cursor-pointer' alt='Telefon'>
              <AiOutlinePhone className='text-6xl border group group-hover:bg-[#282828] hover:text-white hover:border-none transition-all border-black rounded-full p-4' />
              <a className='hidden group-hover:block transition-all duration-700'>Bizi Arayın</a>
            </a>
            <a href={'mailto:' + contact?.mail} className='group cursor-pointer' alt='Mail'>
              <AiOutlineMail className='text-6xl border group group-hover:bg-[#282828] hover:text-white hover:border-none transition-all border-black rounded-full p-4' />
            </a>
            <a href={contact?.facebook} className='group cursor-pointer' alt='Facebook'>
              <AiFillFacebook className='text-6xl border group group-hover:bg-[#282828] hover:text-white hover:border-none transition-all border-black rounded-full p-4' />
            </a>
            <a href={contact?.instagram} className='group cursor-pointer' alt='İnstagram'>
              <AiFillInstagram className='text-6xl border group group-hover:bg-[#282828] hover:text-white hover:border-none transition-all border-black rounded-full p-4' />
            </a>
            <a href={contact?.youtube} className='group cursor-pointer' alt='İnstagram'>
               <AiFillYoutube className='text-6xl border group group-hover:bg-[#282828] hover:text-white hover:border-none transition-all border-black rounded-full p-4'/>
              </a>
          </div>
        </div>

        <motion.div
          ref={mapRef}
          className='flex-1 w-full lg:w-1/2 px-6 lg:px-0 flex items-center map justify-center'
        >

        </motion.div>

      </div>

      <Footer></Footer>

    </div>
  )
}

export default Contact